const appReducerDefaultState = {
    adminApp: false,
    pagerFullPageContextId: false,
    pagerFullPages: [],
    menuOpen: false,
    microSite: false,
    microSiteFrontPage: "",
    searchOpen: false,
  },
  appReducer = (state = appReducerDefaultState, action) => {
    switch (action.type) {
      case "ADMIN_APP": {
        return Object.assign({}, state, { adminApp: action.adminApp });
      }
      case "MICROSITE": {
        return Object.assign({}, state, { microSite: action.microSite });
      }
      case "MICROSITE_FRONTPAGE": {
        return Object.assign({}, state, {
          microSiteFrontPage: action.microSiteFrontPage,
        });
      }
      case "MENU_OPEN": {
        if (state.menuOpen) {
          document.body.classList.add("fullscreen-menu-open");
        } else {
          document.body.classList.remove("fullscreen-menu-open");
        }

        return Object.assign({}, state, {
          menuOpen: action.menuOpen,
        });
      }
      case "PAGER_FULL_PAGE": {
        // Remove if they are pushed twice (or after query finish).
        const pagerFullPages = state.pagerFullPages.filter(
          (config) => config.id !== action.config.id
        );

        return Object.assign({}, state, {
          pagerFullPages: [...pagerFullPages, action.config],
        });
      }
      case "PAGER_FULL_PAGE_CONTEXT_ID": {
        return Object.assign({}, state, { pagerFullPageContextId: action.id });
      }
      case "SEARCH_OPEN": {
        return Object.assign({}, state, { searchOpen: action.searchOpen });
      }

      default:
        return state;
    }
  };

export { appReducerDefaultState, appReducer };
