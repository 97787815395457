import React from "react";
import PropTypes from "prop-types";
import Vimeo from "@u-wave/react-vimeo";

const VimeoVideo = (props) => (
  <section className={props.sectionClassNames}>
    {props.content.fieldGroesse === "0" && (
      <div className="full-width">
        <Vimeo
          video={props.content.fieldVimeoVideoUrl}
          responsive
          controls={true}
        />
        {(this.props.content.fieldTitel || this.props.content.fieldBeschreibung) &&
          <div className="text-part">
            {this.props.content.fieldTitel &&
              <div className="title">{this.props.content.fieldTitel}</div>
            }
            {this.props.content.fieldBeschreibung &&
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: this.props.content.fieldBeschreibung }}
              />
            }
          </div>
        }
      </div>
    )}
    {props.content.fieldGroesse === "1" && (
      <div className="container">
        <div className="row">
          <div className="col-18 col-lg-8 offset-lg-4">
            <Vimeo video={props.content.fieldVimeoVideoUrl} responsive />
            {(this.props.content.fieldTitel || this.props.content.fieldBeschreibung) &&
              <div className="text-part">
                {this.props.content.fieldTitel &&
                  <div className="title">{this.props.content.fieldTitel}</div>
                }
                {this.props.content.fieldBeschreibung &&
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: this.props.content.fieldBeschreibung }}
                  />
                }
              </div>
            }
          </div>
        </div>
      </div>
    )}
  </section>
);

VimeoVideo.propTypes = {
  content: PropTypes.shape({
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldVimeoVideoUrl: PropTypes.string.isRequired,
  }),
  sectionClassNames: PropTypes.string,
};

export default VimeoVideo;
