import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../general-components/loading-indicator";
import Error500 from "../../system/error-500";
import Error404 from "../../system/error-404";
import { Helmet } from "react-helmet";

const NodeWrapper = ({ entity, loading, error, children }) => {
  // Return the loading indicator as long as query is not completed.
  if (loading) {
    return <LoadingIndicator />;
  }

  // Return the 500 error component if query was not successful.
  if (error) {
    return <Error500 error={error} />;
  }

  // Return the 404 error component if query was successful but data is empty.
  if (!entity && !loading) {
    return <Error404 />;
  }

  const published = entity.status ? "node-published" : "node-unpublished";

  return (
    <article
      className={`node-${entity.entityBundle} node-${entity.id} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`${entity.label} | Landesverband der Freien Theater in Sachsen e.V.`}</title>
      </Helmet>

      {children}
    </article>
  );
};

NodeWrapper.propTypes = {
  entity: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  children: PropTypes.node,
};

export default NodeWrapper;
