import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";
import { LinkPropType } from "../../../link/link";
import Slider from "react-slick";
import CustomArrow from "../../../..//controls/custom-arrow";

const ParagraphLogosPartners = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-logos-partners": true,
  });

  const sliderSettings = {
    arrows: true,
    dots: false,
    // width={48} height={48} viewBox="0 0 32 24" is not used inside of the component.
    nextArrow: <CustomArrow classNames={classNames}/>,
    prevArrow: <CustomArrow classNames={classNames}/>,
    slidesToShow: 3,
    infinite: false,
    centerMode: false,
    slidesToScroll: 1,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          touchMove: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          touchMove: true,
        }
      }
    ]
  };
  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-12 offset-md-2">
            {content.fieldHeading &&
              <div className="heading">{content.fieldHeading}</div>
            }
            <Slider {...sliderSettings}>
              {content.fieldLogosPartners.map((item, index) => (
                <div key={index} className="col inner-col">
                  {item.fieldLink !== null ? (
                    <div className="logo-frame">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.fieldLink.uri.path}
                      >
                        {item.fieldMediaImage1 &&
                          <Image data={item.fieldMediaImage1} />
                        }
                      </a>
                    </div>
                  ) : (
                    <ErrorBoundary>
                      <div className="logo-frame">
                        {item.fieldMediaImage1 &&
                          <Image data={item.fieldMediaImage1} />
                        }
                      </div>
                    </ErrorBoundary>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

    </section>
  );
};

ParagraphLogosPartners.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLogosPartners: PropTypes.arrayOf(
      PropTypes.shape({
        fieldLink: LinkPropType,
        fieldMediaImage1: PropTypes.shape({
          style: PropTypes.shape({
            url: PropTypes.string
          }).isRequired,
          styleFullPage: null,
          desktop: null,
          mobile: null,
        }),
      })
    ),
  }),
};

export default ParagraphLogosPartners;
