import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import Slider from "react-slick";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";
import { connect } from "react-redux";
import { self } from "../../../../../config";
import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";
import CustomArrow from "../../../../../controls/custom-arrow";
import classNames from "classnames";




const sliderSettings = {
  adaptiveHeight: false,
  arrows: true,
  centerMode: false,
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  touchMove: false,
  nextArrow: <CustomArrow classNames={classNames}/>,
  prevArrow: <CustomArrow classNames={classNames}/>,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        touchMove: true,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        touchMove: true,
      }
    }
  ]
};


const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class ComponentTeaserlistCarousel extends React.Component {
  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes?.entityQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.entityQuery.items,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes?.entityQuery) !==
      JSON.stringify(this.props.nodes?.entityQuery)
    ) {
      this.pushPagerFullPageConfig();
    }
  }

  render() {
    if (this.props.nodes?.loading) {
      return false;
    }

    return (
      <>
        {(this.props.nodesConfig === "Manuell" &&
          this.props.manualNodes.length >= 1) ||
        (this.props.nodesConfig !== "Manuell" &&
          this.props.nodes?.entityQuery &&
          this.props.nodes?.entityQuery.items.length >= 1) ? (
          <>
            {this.props.nodesConfig === "Manuell" ? (
              <div className="container">
                <div className="row">
                  <div className="col-16 col-lg-12 offset-lg-2 col-md-14 offset-md-1">
                    <Slider {...sliderSettings}>
                      {this.props.manualNodes.map((item, index) => (
                        <React.Fragment key={index}>
                          {(() => {
                            switch (item.entityBundle) {
                              case "news":
                                return (
                                  <TeaserNews
                                    item={item.entity}
                                    pagerFullPage={this.props.pagerFullPage}
                                  />
                                );
                              case "person":
                                return (
                                  <div className="teaser-wrapper">
                                    <TeaserPerson
                                      item={item}
                                      pagerFullPage={this.props.pagerFullPage}
                                    />
                                  </div>
                                );
                              default:
                                return null;
                            }
                          })()}
                        </React.Fragment>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {this.props.nodes?.entityQuery &&
                this.props.nodes?.entityQuery.items.length > 0 ? (
                  <>
                    <div className="container">
                      <div className="row">
                        <div className="col-16 col-lg-12 offset-lg-2 col-md-14 offset-md-1">
                          <Slider {...sliderSettings}>
                            {this.props.nodes?.entityQuery.items.map((item, index) => (
                              <React.Fragment key={index}>
                                {(() => {
                                  switch (item.entityBundle) {
                                    case "news":
                                      return (
                                        <TeaserNews
                                          item={item}
                                          pagerFullPage={this.props.pagerFullPage}
                                        />
                                      );
                                    case "person":
                                      return (
                                        <div className="teaser-wrapper">
                                          <TeaserPerson
                                            item={item}
                                            pagerFullPage={this.props.pagerFullPage}
                                          />
                                        </div>

                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </React.Fragment>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </div>

                  </>
                ) : (
                  <LoadingIndicator />
                )}
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
}

ComponentTeaserlistCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      teaserNewsPropTypes,
      teaserEventPropTypes,
      teaserPersonPropTypes,
      teaserGeneralPropTypes,
      teaserProjectPropTypes,
    ])
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  nodes: PropTypes.shape({
    entityQuery: PropTypes.shape({
      items: PropTypes.array,
      total: PropTypes.number,
    }),
    loading: PropTypes.bool,
  }),
  dispatch: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell",
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "all" ? ["news", "person"] : [props.type],
        tag: props.tags.map((item) => item.id.toString()),
        filterTagEnabled: props.tags.length > 0,
        language: props.currentLanguage.toUpperCase(),
      },
    }),
  })(ComponentTeaserlistCarousel)
);

export { sliderSettings };
