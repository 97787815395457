import React from "react";
import { pagerFullPageContextAction } from "../../../app-actions";
import { Link, withRouter } from "react-router-dom";


function teaserProjektList(item) {

  return (
    <div className="col-16 list-row">
      <Link
        to={
          item.item.url
            ? item.item.url.path
            : item.item.path.alias
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-16 title">
              {item.item.title &&
                item.item.title
              }
            </div>
            <div className="col-16">
              {item.item.fieldExtraInformation &&
                <div
                  className="additional-information"
                  dangerouslySetInnerHTML={{
                    __html: item.item.fieldExtraInformation
                  }}
                />
              }
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default teaserProjektList;