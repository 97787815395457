import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronUp24, ChevronDown24 } from "@carbon/icons-react";

import Image from "../../../image/image";
import FieldTime from "../../../date-time/field-time";
import ErrorBoundary from "../../../../error-boundary";
import { self } from "../../../../config";
const ParagraphDownloadSchublade = ({ content }) => {
  {/*
    const [open, setOpen] = useState(false);
  */}

  const sectionClassNames = classNames({
    "paragraph paragraph-download-schublade": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <h3>{content.fieldTitel}</h3>
          </div>
          <div className="col-16 downloads">
            {content.fieldDownloads.map((item, index) => (
              <a
                key={index}
                href={item.mediaFileUrl.path}
                download={true}
                className="download"
              >
                {item.label}
                <img src={`${self}/arrow.svg`} className="download-arrow"/>
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphDownloadSchublade.propTypes = {
  content: PropTypes.shape({
    fieldDatum: PropTypes.shape({
      value: PropTypes.string,
    }),
    fieldRubrikOderKategorie: PropTypes.string,
    fieldTitel: PropTypes.string,
    fieldBeschreibung: PropTypes.string,
    fieldDownloads: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        fieldVorschaubild: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            derivative: PropTypes.shape({
              urlPath: PropTypes.string,
            }),
          }),
        }),
        mediaFileUrl: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
};

export default ParagraphDownloadSchublade;
