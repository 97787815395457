import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Link from "./../../../link/link"
import Marquee from 'react-fast-marquee';

const ParagraphMarquee = ({ content }) => {
  return (
    <section className="paragraph paragraph-marquee">
      <Marquee pauseOnHover={true} speed={100} gradient={false} autoFill={true} direction={"left"} className="marquee">
        <Link link={content.fieldLink}/>
      </Marquee>
    </section>
  );
};

export default ParagraphMarquee;
