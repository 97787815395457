import React, { useEffect, useState } from "react";
import Image from "../../../image/image";

const ParagraphImage = ({ content }) => {
  const AbschnittSwitch = ({ content }) => {
    switch (content.fieldFormatWidth) {
      case "Centered":
        return (
          <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-2 col-16">
                {content.fieldImage && (
                  <Image
                    data={content.fieldImage.fieldMediaImage}
                    credit={content.fieldImage.fieldCredit}
                    className="single-image centered"
                  />
                )}
              </div>
            </div>
          </div>
        );
      case "Full-bleed":
        return (
          <div className="container-fluid">
            {content.fieldImage && (
              <Image
                data={content.fieldImage.fieldMediaImage}
                credit={content.fieldImage.fieldCredit}
                className="single-image full-bleed"
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <section
      className="paragraph paragraph-image"
      data-format={content.fieldFormatWidth.toLowerCase()}
    >
      <AbschnittSwitch content={content} />
    </section>
  );
};

export default ParagraphImage;
