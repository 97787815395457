import React from "react";
import PropTypes from "prop-types";

const ParagraphFacts = ({ content }) => {
  return (
      <div className="expandable-wrap">
          {content.fieldAbschnitte.map((item, i) => (
            <div className="row" key={i}>
              <div className="col-6">
                {item.fieldLabel &&
                  <div className="label">{item.fieldLabel}</div>
                }
              </div>
              <div className="col-10">
                {item.fieldFact &&
                  <div className="fact">{item.fieldFact}</div>
                }
              </div>
            </div>
          ))}
      </div>
  );
};


ParagraphFacts.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        fieldLabel: PropTypes.string.isRequired,
        fieldFact: PropTypes.string.isRequired,
      })
    ),
  }),
};


export default ParagraphFacts;
