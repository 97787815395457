import React, { Component } from "react";
import PropTypes from "prop-types";

import ParagraphHeroText from "./paragraphs/hero-text/paragraph-hero-text";
import ContentCloud from "./paragraphs/content-cloud/paragraph-content-cloud";
import ParagraphTextAndSpeech from "./paragraphs/text-and-speech/paragraph-text-and-speech";
import ParagraphClickSuggestions from "./paragraphs/click-suggestions/paragraph-click-suggestions";
import ParagraphTeaserList from "./paragraphs/teaserliste/paragraph-teaser-list";
import ParagraphTeaserImage from "./paragraphs/teaser-image/paragraph-teaser-image";
import ParagraphSectionheading from "./paragraphs/section-heading/paragraph-sectionheading";
import ParagraphText from "./paragraphs/text/paragraph-text";
import Eforkbot from "./../../eforkbot/eforkbot";
import ParagraphBilderSlider from "./paragraphs/image-image-slider/paragraph-bilder-slider";
import ParagraphNumberFacts from "./paragraphs/number-facts/paragraph-number-facts";
import ParagraphTeaserSlider from "./paragraphs/teaser-slider/paragraph-teaser-slider";
import ParagraphNewsletteranmeldung from "./paragraphs/newsletter/paragraph-newsletteranmeldung";
import ParagraphAkkordionText from "./paragraphs/akkordion-text/paragraph-akkordion-text";
import ParagraphDossier from "./paragraphs/dossier/paragraph-dossier";
import ParagraphMedia from "./paragraphs/media-video-audio/paragraph-media";
import ParagraphPageTitle from "./paragraphs/page-title/paragraph-page-title";
import ParagraphCallToAction from "./paragraphs/cta/paragraph-call-to-action";
import ParagraphExtendedTeaserOverview from "./paragraphs/extended-teaser-list/paragraph-extended-teaser-overview";
import ParagraphCalendarInformation from "./paragraphs/kalender-information/paragraph-calendar-information";
import ParagraphDownload from "./paragraphs/download/paragraph-download";
import ParagraphZitat from "./paragraphs/zitat/paragraph-zitat";
import ParagraphDownloadSchublade from "./paragraphs/download-schublade/paragraph-download-schublade";
import ParagraphTimeline from "./paragraphs/timeline/paragraph-timeline";
import ParagraphKontakt from "./paragraphs/kontakt/paragraph-kontakt";
import ParagraphStrukturierteListe from "./paragraphs/strukturierte-liste/paragraph-strukturierte-liste";
import ParagraphPersonenInformationen from "./paragraphs/personen-informationen/paragraph-personen-informationen";
import ParagraphKalenderEinzelDoppel from "./paragraphs/kalender-einzel-doppel/paragraph-kalender-einzel-doppel";
import ParagraphKalenderAbschnitt from "./paragraphs/kalender-abschnitt/paragraph-kalender-abschnitt";
import ParagraphKalenderMonat from "./paragraphs/kalender-monat/paragraph-kalender-monat";
import ParagraphAudio from "./paragraphs/audio/paragraph-audio";
import ParagraphLogosPartners from "./paragraphs/logos-partners/logos-partners";
import ParagraphTeaserListEvents from "./paragraphs/teaserliste/events/paragraph-teaser-list-events";
import ParagraphFormular from "./paragraphs/webform/paragraph-formular";
import ParagraphMarquee from "./paragraphs/marquee/paragraph-marquee";
import ParagraphImage from "./paragraphs/paragraph-image/paragraph-image";

import ErrorBoundary from "../../error-boundary";
import ParagraphTeaserListPages from "./paragraphs/teaserliste/pages/paragraph-teaser-list-pages";
import ParagraphExtendedTeaserOverviewProjects from "./paragraphs/extended-teaser-list/projects/paragraph-extended-teaser-overview-projects";
import ParagraphNewsletterRegistration from "./paragraphs/newsletter-registration/paragraph-newsletter-registration";
import ParagraphVideo from "./paragraphs/video/paragraph-video";

const ParagraphSwitch = (props) => {
  switch (props.paragraphItem.entityBundle) {
    case "content_cloud":
      return <ContentCloud content={props.props.paragraphItem} />;
    case "text_and_speech":
      return <ParagraphTextAndSpeech content={props.paragraphItem} />;
    case "audio":
      return <ParagraphAudio content={props.paragraphItem} />;
    case "media":
      return <ParagraphMedia content={props.paragraphItem} />;
    case "hero_text":
      return <ParagraphHeroText content={props.paragraphItem} />;
    case "click_suggestions":
      return <ParagraphClickSuggestions content={props.paragraphItem} />;
    case "teaserliste":
    case "teaserliste_news":
    case "teaserliste_personen":
      return <ParagraphTeaserList content={props.paragraphItem} />;
    case "teaserliste_events":
      return <ParagraphTeaserListEvents content={props.paragraphItem} />;
    case "teaserliste_pages":
      return <ParagraphTeaserListPages content={props.paragraphItem} />;
    case "teaser_image_text":
      return <ParagraphTeaserImage content={props.paragraphItem} />;
    case "section_heading":
      return <ParagraphSectionheading content={props.paragraphItem} />;
    case "marquee":
      return <ParagraphMarquee content={props.paragraphItem} />;
    case "image":
      return <ParagraphImage content={props.paragraphItem} />;
    case "text":
      return <ParagraphText content={props.paragraphItem} />;
    case "bilder_slider":
      return <ParagraphBilderSlider content={props.paragraphItem} />;
    case "zahlen_und_fakten":
      return <ParagraphNumberFacts content={props.paragraphItem} />;
    case "teaser_slider":
      return <ParagraphTeaserSlider content={props.paragraphItem} />;
    case "formular":
      return <ParagraphFormular content={props.paragraphItem} />;
    case "newsletteranmeldung":
      return <ParagraphNewsletteranmeldung content={props.paragraphItem} />;
    case "text_akkordion":
      return <ParagraphAkkordionText content={props.paragraphItem} />;
    case "chatbot":
      return <Eforkbot />;
    case "teaser_sammlung_dossier_":
      return <ParagraphDossier content={props.paragraphItem} />;
    case "seitentitel":
      return <ParagraphPageTitle content={props.paragraphItem} />;
    case "call_to_action":
      return <ParagraphCallToAction content={props.paragraphItem} />;
    case "erweiterte_teaseruebersicht":
    case "member_overview":
    case "teaseruebersicht_events":
    case "teaseruebersicht_personen": {
      const nodes = {};

      if (props.paragraphItem.fieldManuelleSortierung) {
        nodes.loading = false;
        nodes.nodeQuery = {};
        nodes.nodeQuery.entities = props.paragraphItem.fieldBeitraege.map(
          (node) => node.entity
        );

        return (
          <ParagraphExtendedTeaserOverview
            content={props.paragraphItem}
            nodes={nodes}
          />
        );
      }

      return <ParagraphExtendedTeaserOverview content={props.paragraphItem} />;
    }
    case "teaseruebersicht_projekte": {
      const nodes = {};

      if (props.paragraphItem.fieldManuelleSortierung) {
        nodes.loading = false;
        nodes.nodeQuery = {};
        nodes.nodeQuery.entities = props.paragraphItem.fieldBeitraege.map(
          (node) => node.entity
        );

        return (
          <ParagraphExtendedTeaserOverviewProjects
            content={props.paragraphItem}
            nodes={nodes}
          />
        );
      }

      return (
        <ParagraphExtendedTeaserOverviewProjects
          content={props.paragraphItem}
        />
      );
    }
    case "veranstaltungsinformationen":
      return (
        <ParagraphCalendarInformation
          content={props.paragraphItem}
          nodeContent={props.nodeContent}
        />
      );
    case "personeninformationen":
      return (
        <ParagraphPersonenInformationen
          content={props.paragraphItem}
          nodeContent={props.nodeContent}
        />
      );
    case "download":
      return (
        <ParagraphDownload
          content={props.paragraphItem}
          nodeContent={props.nodeContent}
        />
      );
    case "zitat":
      return (
        <ParagraphZitat
          content={props.paragraphItem}
          nodeContent={props.nodeContent}
        />
      );
    case "download_schublade":
      return (
        <ParagraphDownloadSchublade
          content={props.paragraphItem}
          nodeContent={props.nodeContent}
        />
      );
    case "timeline":
      return (
        <ParagraphTimeline
          content={props.paragraphItem}
          nodeContent={props.nodeContent}
        />
      );
    case "kontakt":
      return (
        <ParagraphKontakt
          content={props.paragraphItem}
          nodeContent={props.nodeContent}
        />
      );
    case "strukturierte_liste":
      return (
        <ParagraphStrukturierteListe
          content={props.paragraphItem}
          nodeContent={props.nodeContent}
        />
      );

    case "newsletter_registration":
      return <ParagraphNewsletterRegistration />;
    case "kalender_doppel_einzel_highlight":
      return <ParagraphKalenderEinzelDoppel content={props.paragraphItem} />;
    case "kalenderabschnitt":
      return <ParagraphKalenderAbschnitt content={props.paragraphItem} />;
    case "kalendermonatsuebersicht":
      return <ParagraphKalenderMonat content={props.paragraphItem} />;
    case "logos_partners":
      return <ParagraphLogosPartners content={props.paragraphItem} />;
    case "video":
      return <ParagraphVideo content={props.paragraphItem} />;
    default:
      return null;
  }
};

class ContentBase extends Component {
  render() {
    return (
      <section className={`content-wrap ${this.props.className}`}>
        {this.props.content.map((item, index) => {
          let paragraphItem = item.entity;

          if (paragraphItem.entityBundle === "from_library") {
            paragraphItem =
              item.entity.fieldReusableParagraphRawField.first.entity
                .paragraphsRawField.first.entity;
          }

          let previousColour =
            (this.props.content[index - 1] &&
              !this.props.content[index - 1].entity.fieldColour &&
              !paragraphItem.fieldColour) ||
            (this.props.content[index - 1] &&
              paragraphItem.fieldColour &&
              paragraphItem.fieldColour ===
                this.props.content[index - 1].entity.fieldColour)
              ? "same-as-before"
              : "different-than-before";

          return (
            <div
              className={`module-wrapper ${paragraphItem.entityBundle}`}
              data-colour-scheme={
                paragraphItem.fieldColour
                  ? paragraphItem.fieldColour.name.toLowerCase()
                  : "unset"
              }
              data-previous-colour={previousColour}
              key={index}
            >
              <ParagraphSwitch paragraphItem={paragraphItem} />
            </div>
          );
        })}
      </section>
    );
  }
}

ContentBase.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldReusableParagraph: PropTypes.object,
        entityBundle: PropTypes.string.isRequired,
        fieldManuelleSortierung: PropTypes.bool,
      }),
    })
  ),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
};

export default ContentBase;
