import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import WebformElements from "../webform-elements";

const CompositeField = ({ item, token, generatedInitialValues }) => {
  const { values } = useFormikContext();

  const { invisible, visible, optional, required } = states(
    item["#states"],
    values
  );

  const initialValue = values[item.id];

  return (
    <div
      className={classNames({
        "form-group custom-composite": true,
        hidden: invisible || !visible,
      })}
      id={item.id}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label htmlFor={item.id}>
        {item["#title"]}
        {(!!item["#required"] || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      {!!item["#description"] && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item["#description"] }}
        />
      )}

      {/* @see https://formik.org/docs/api/fieldarray */}
      <FieldArray
        name={item.id}
        render={(arrayHelpers) => (
          <>
            <div className="form-row">
              {[...Array(initialValue.length)].map((i, index) => (
                <div key={index} className="custom-composite-element">
                  {item["#multiple"] ? (
                    <div className="top-bar">
                      <span className="amount-indicator">
                        {index + 1}/{item["#multiple"]}
                      </span>
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        aria-label="Element entfernen"
                        title="Element entfernen"
                      >
                        ✖
                      </button>
                    </div>
                  ) : null}
                  <WebformElements
                    items={item.elements}
                    token={token}
                    compositeIndex={item["#multiple"] ? index : undefined}
                    compositeParent={item.id}
                  />
                </div>
              ))}
              {!!item["#multiple"] &&
              (item["#multiple"] === 0 ||
                item["#multiple"] > initialValue.length) ? (
                <div className="add">
                  <button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push(generatedInitialValues[item.id][0])
                    }
                    aria-label="Element hinzufügen"
                    title="Element hinzufügen"
                  >
                    +
                  </button>
                </div>
              ) : null}
            </div>
          </>
        )}
      />

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
    </div>
  );
};

CompositeField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    "#states": PropTypes.object,
    "#flex": PropTypes.string,
    "#title": PropTypes.string,
    "#description": PropTypes.string,
    "#required": PropTypes.bool,
    "#multiple": PropTypes.number,
    elements: PropTypes.array,
  }),
  token: PropTypes.string.isRequired,
  generatedInitialValues: PropTypes.object,
};

export default CompositeField;
