import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import WebformElements from "../webform-elements";
import { FormattedMessage } from "react-intl";

const Container = ({ item, token }) => {
  const { values } = useFormikContext();

  const { invisible, visible } = states(item["#states"], values);

  return (
    <div
      className={classNames({
        "form-group webform-container": true,
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
      id={item.id}
    >
      <h2>
        <FormattedMessage id={"form." + item.id} />
      </h2>
      <WebformElements items={item.elements} token={token} />

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item["#description"] && (
        <small className="form-description text-muted form-text">
          {item["#description"]}
        </small>
      )}
    </div>
  );
};

Container.propTypes = {
  item: PropTypes.shape({
    elements: PropTypes.array,
    "#description": PropTypes.string,
    "#flex": PropTypes.string,
    id: PropTypes.string,
    "#states": PropTypes.object,
  }),
  token: PropTypes.string.isRequired,
};

export default Container;
