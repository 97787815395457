import React from "react";

const NewsletterDOIConfirmed = () => {
  return (
    <section className="paragraph">
      <div className="container">
        <div className="text">
          <h1>Vielen Dank</h1>
          <p>
            Die Newsletteranmeldung war erfolgreich.
            <br />
            Sie können sich jederzeit über den Link in unserem Newsletter wieder
            abmelden.
          </p>
        </div>
      </div>
    </section>
  );
};

export default NewsletterDOIConfirmed;
