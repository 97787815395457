import React, { useState } from "react";
import PropTypes from "prop-types";
import Video from "./components/video";
import classNames from "classnames";

const ParagraphVideo = ({ content }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);

  const classes = classNames({
    "col-16": true,
    "col-lg-12 offset-lg-2": content.fieldSize === "centered",
  });

  return (
    <section className="paragraph paragraph-video">
      <div className="container">
        <div className="row">
          <div className={classes}>
            <div className="video-wrapper">
              <Video content={content.fieldVideoFile} onPlay={() => setVideoPlaying(true)} />
              {(content.fieldText || content.fieldTitel) && !+videoPlaying && (
                <div className="video-meta">
                  {content.fieldTitel && <h2>{content.fieldTitel}</h2>}
                  {content.fieldText && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: content.fieldText }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const videoThumbnailPropTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.shape({
    urlPath: PropTypes.string,
  }),
};

export const videoFilePropTypes = {
  thumbnail: PropTypes.shape(videoThumbnailPropTypes),
  mediaFileUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export const videoEmbedPropTypes = {
  thumbnail: PropTypes.shape(videoThumbnailPropTypes),
  fieldMediaOembedVideo: PropTypes.string,
};

ParagraphVideo.propTypes = {
  content: PropTypes.shape({
    /* fieldVideoFile returns either type MediaEmbeddedVideo or MediaVideo */
    fieldVideoFile: PropTypes.oneOf([
      /* Video File */
      PropTypes.shape(videoFilePropTypes),

      /* Embedded Video (YouTube/Vimeo) */
      PropTypes.shape(videoEmbedPropTypes),
    ]),
    fieldText: PropTypes.string,
    fieldTitel: PropTypes.string,
    fieldSize: PropTypes.oneOf(["center", "full"]),
  }),
};

export default ParagraphVideo;
