import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Contacts from "./../../contacts/contacts";
import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import { self } from "../../../config";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";


/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserMember = (props) => {
  return (
    <article className="node node-teaser teaser-member col-16">
      <EditButton
        adminApp={props.adminApp}
        entityId={props.item.id}
        destinationRoute={props.location.pathname}
      />
      <Accordion allowMultipleExpanded={false} allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton className="accordion-btn">
              <div className="row">
                <div className="col-14 col-lg-15 item-col">
                  <h3>
                    {props.item.fieldTitle &&
                      <>{props.item.fieldTitle}{" "}</>
                    }
                    {props.item.fieldFirstName &&
                      <>{props.item.fieldFirstName}{" "}</>
                    }
                    {props.item.fieldLastName &&
                      <>{props.item.fieldLastName}{" "}</>
                    }
                  </h3>
                </div>
                <div className="col-2 col-lg-1 btn-col">
                  <img className="down-btn" src={`${self}/arrow.svg`} alt="pfeil zum Öffnen/schließen"/>
                </div>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          {/*@todo fhe: accordionPanel doesn't get the attribute aria-hidden="true" therefore transition-mixin is not applicable*/}
          <AccordionItemPanel>
            <div className="content">
              {props.item.fieldImages &&
                <div className="images-wrapper">
                  <div className="row">
                    {props.item.fieldImages.map((item, index) => (
                      <div className="col-16 col-lg-4 image" key={index}>
                        <Image
                          data={item.fieldMediaImage}
                          credit={item.fieldCredit}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              }
              {props.item.fieldText &&
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: props.item.fieldText
                  }}
                />
              }
              <Contacts
                web={props.item.fieldWebsite}
                mail={props.item.fieldMail}
                insta={props.item.fieldInstagram}
              />
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </article>
  );
}

export const teaserMemberPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  fieldMail: PropTypes.string,
  fieldTelefon: PropTypes.string,
  fieldMobil: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string
  }),
  url: PropTypes.shape({
    path: PropTypes.string
  }),
  fieldBeschreibung: PropTypes.string,
  fieldKontakinformationen: PropTypes.arrayOf(
    PropTypes.shape({
      fieldContactType: PropTypes.string.isRequired,
      fieldWert: PropTypes.string.isRequired
    })
  ),
  fieldRolleOderFunktion: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  fieldBildWCaptionRawField: PropTypes.shape({
    first: PropTypes.shape({
      customCaption: PropTypes.string,
      defaultCaption: PropTypes.bool,
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
});

TeaserMember.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserMemberPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserMember));
