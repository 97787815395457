import React, { Component } from "react";
import { Link } from "react-router-dom";
import {self} from '../config';

class LogoButton extends Component {
  render() {
    return (
      <Link className="site-logo-button" to="/startseite">
        <img
          src={`${self}/lfts-logo${this.props.type === 'maximal' ? '-max' : ''}.svg`}
          className="logo"
          alt={"LFTS Logo"}
        />
        <span className="sr-only">
          Logo & Panic-Button
        </span>
      </Link>
    );
  }
}

LogoButton.propTypes = {};

export default LogoButton;
