import React from "react";
import { Menu } from "../general-components/navigation/menu";
import { SocialMedia } from "../footer/components/social-media";

import configQuery from "../general-components/config-query.graphql";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { menuOpenAction } from "../app-actions";

function ShortcutMenu() {
  const { loading, error, data } = useQuery(configQuery, {
    variables: { name: "footer_social_media" },
  });

  const dispatch = useDispatch();

  return (
    <ul className="upper-part">
      <Menu
        menuName="SHORTCUT_MENU"
        listClassName="list"
        navClassName="shortcut-menu"
        itemOnClickHandler={() => dispatch(menuOpenAction(false))}
      />
      {!loading && data?.entityQuery?.items[0] && (
        <SocialMedia footerConfig={data.entityQuery.items[0]} />
      )}
    </ul>
  );
}
export default ShortcutMenu;
