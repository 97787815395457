import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { graphql } from "@apollo/client/react/hoc";

import LoadingIndicator from "../general-components/loading-indicator";
import { SocialMedia } from "./components/social-media";
import configQuery from "../general-components/config-query.graphql";
import { Menu } from "../general-components/navigation/menu";
import {self} from "../config";
import Contacts from "./../general-components/contacts/contacts"

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  reduxStore,
});

class PageFooter extends Component {
  render() {
    const footerConfig =
      this.props.footerConfig?.entityQuery?.items?.length > 0
        ? this.props.footerConfig.entityQuery.items[0]
        : null;

    if (footerConfig) {
      return (
        <footer id="pageFooter">
          <div className="container">
            {footerConfig && (
              <>
                <div className="row">
                  <div className="col-16 col-md-6">
                    <Contacts
                      mail={footerConfig.fieldEMail}
                      tel={footerConfig.fieldTel}
                      address={footerConfig.fieldAddress}
                    />
                    <Menu
                      itemClassName="col"
                      listClassName="row"
                      menuName="FOOTER"
                    />
                    <SocialMedia footerConfig={footerConfig} />
                  </div>

                  <div className="col-16 col-md-8">
                    {footerConfig.fieldFooterText &&
                      <div
                        className="text main"
                        dangerouslySetInnerHTML={{ __html: footerConfig.fieldFooterText }}
                      />
                    }
                  </div>

                  <div className="col-16 col-md-10 support-text-wrapper">
                    {footerConfig.fieldSponsoringText &&
                      <div
                        className="text sponsoring"
                        dangerouslySetInnerHTML={{ __html: footerConfig.fieldSponsoringText }}
                      />
                    }
                  </div>
                  <div className="col-16 col-md-6 support-image-wrapper">
                    <img className="support-logos" alt={"Logos der Fördernden: Stadt Dresden und Land Sachsen"} src={`${self}/support_logos.svg`}/>
                  </div>
                </div>
              </>
            )}
          </div>
        </footer>
      );
    }

    return <LoadingIndicator />;
  }
}

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  footerConfig: PropTypes.shape({
    entityQuery: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          fieldFooterSitemapZeigen: PropTypes.bool,
          fieldEMail: PropTypes.string.isRequired,
          fieldTel: PropTypes.string.isRequired,
          fieldAddress: PropTypes.string.isRequired,
          fieldFooterText: PropTypes.string,
          fieldSponsoringText: PropTypes.string,
          fieldMenue: PropTypes.shape({
            targetId: PropTypes.string,
          }),
          fieldFacebook: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldYoutube: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldFlickr: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldInstagram: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldPinterest: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldLinkedin: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldTwitter: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldVimeo: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldXing: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldTumblr: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
        })
      ),
    }),
  }),
};

export default connect(mapStateToProps)(
  compose(
    graphql(configQuery, {
      name: "footerConfig",
      options: () => ({
        variables: {
          name: "footer_social_media",
        },
      }),
    })
  )(PageFooter)
);
