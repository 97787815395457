import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { videoFilePropTypes } from "../paragraph-video";
import { Pause24, Play24 } from "@carbon/icons-react";
import { restHostBackend } from "../../../../../config";

const VideoFile = ({ content, onPlay }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef();
  const videoWrapperRef = useRef();

  const videoFile = content;

  const playPauseVideo = () => {
    setVideoPlaying((prev) => {
      const next = !prev;

      if (next) {
        videoRef.current.play();
        onPlay();
      }
      if (!next) videoRef.current.pause();

      return next;
    });
  };

  // video keyboard controls
  const handleKeyDownVideo = (event) => {
    const video = videoRef.current;

    switch (event.keyCode) {
      case 32: // Space
        event.preventDefault();
        playPauseVideo();
        break;
      case 37: // Left arrow
        event.preventDefault();
        video.currentTime -= 10;
        break;
      case 39: // Right arrow
        event.preventDefault();
        video.currentTime += 10;
        break;
      case 38: // Up arrow
        event.preventDefault();
        video.volume += 0.1;
        break;
      case 40: // Down arrow
        event.preventDefault();
        video.volume -= 0.1;
        break;
      case 77: // M
        event.preventDefault();
        if (video.muted) {
          video.muted = false;
        } else {
          video.muted = true;
        }
        break;
    }
  };

  return (
    <div className="full-width local-video" ref={videoWrapperRef}>
      {videoFile?.mediaFileUrl.path && (
        <>
          <video
            src={videoFile.mediaFileUrl.path}
            tabIndex={0}
            controls={false}
            aria-controls="playpause"
            onEnded={() => setVideoPlaying(false)}
            onClick={playPauseVideo}
            onKeyDown={handleKeyDownVideo}
            ref={videoRef}
          />
          <div
            tabIndex={0}
            className="playpause"
            role="button"
            aria-label="Play or pause the video"
            onKeyDown={handleKeyDownVideo}
            onClick={() => playPauseVideo()}
          >
            {!videoPlaying && (
              <img className="icon icon-play" src="/icon_play.svg" />
            )}
          </div>
        </>
      )}
    </div>
  );
};

VideoFile.propTypes = {
  content: PropTypes.shape(videoFilePropTypes),
  onPlay: PropTypes.func,
};

export default VideoFile;
