import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
import { self } from "../../../config";
import Contacts from "./../../contacts/contacts";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import EditButton from "../../../backend/edit-button";
import classNames from "classnames";
import { SocialMedia } from "../../../footer/components/social-media";
import getEncodedIcalUrl from "../../../lib/get-encoded-ical-url";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo use "FieldTime"
 */
class TeaserEvent extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    const startMoment = moment.utc(this.props.item.fieldDatum.value).local();
    const endMoment = moment.utc(this.props.item.fieldDatum.endValue).local();

    const multiday =
      startMoment.format("DD-MM-YYYY") !== endMoment.format("DD-MM-YYYY") &&
      !this.props.item.fieldDauerAusblenden;

    const allday =
      startMoment.format("HH:mm") === "00:00" &&
      endMoment.format("HH:mm") === "23:59";

    const multidayClassNames = classNames({
      "start-date": true,
      multiday: multiday === true,
    });
    return (
      <AccordionItem>
        <article
          className={`node node-${this.props.item.id} node-teaser node-teaser-default teaser-veranstaltung`}
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.id}
            destinationRoute={this.props.location.pathname}
          />
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className="container">
                <div className="row">
                  <div className="col-8 col-lg-4 date-col item-col">
                    <div className="flex-wrap d-flex">
                      <div className="date">
                        <h2 className={multidayClassNames}>
                          {startMoment.format("DD.MM.")}
                        </h2>
                        {multiday && (
                          <h2 className="end-date">
                            {endMoment.format("DD.MM.")}
                          </h2>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-8 col-lg-4 time-col item-col">
                    {allday ? (
                      <div className="flex-wrap d-flex">
                        <span className="start-label">
                          <FormattedMessage id="event.all_day" />
                        </span>
                      </div>
                    ) : (
                      <div className="flex-wrap d-flex">
                        {this.props.item.fieldDatum.value && (
                          <div className="start-label">
                            <FormattedMessage id="event.start-label" />
                          </div>
                        )}
                        {this.props.item.fieldDatum.value && (
                          <h2 className="time">
                            {startMoment.format("HH:mm")}{" "}
                            <FormattedMessage id="event.time" />
                          </h2>
                        )}
                        {this.props.item.fieldDatum.endValue && !this.props.item.fieldDauerAusblenden && (
                          <h2 className="time">
                            {endMoment.format("HH:mm")}{" "}
                            <FormattedMessage id="event.time" />
                          </h2>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-14 col-md-15 col-lg-7 title-col item-col">
                    <div className="flex-wrap d-flex">
                      {this.props.item.title && (
                        <h2>{this.props.item.title}</h2>
                      )}
                      {this.props.item.fieldLatestRegistration && (
                        <div className="latest-registration">
                          <FormattedMessage id="event.latest-registration" />
                          {moment
                            .utc(this.props.item.fieldLatestRegistration.value)
                            .local()
                            .format("DD.MM.YYYY")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-2 col-md-1 btn-col item-col">
                    <img
                      className="down-btn"
                      src={`${self}/arrow.svg`}
                      alt="Pfeil zum Öffnen/Schließen"
                    />
                  </div>
                </div>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>

          <AccordionItemPanel>
            <div className="panel-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-16 extra-information">
                    <div className="row">
                      <div className="col-16 col-xl-8 left-col">
                        <Contacts
                          tel={this.props.item.fieldMobil}
                          mail={this.props.item.fieldMail}
                        />
                        <SocialMedia footerConfig={this.props.item} />
                      </div>
                      <div className="col-16 col-xl-8 right-col">
                        {this.props.item.fieldOrganizer && (
                          <div className="organizer">
                            {this.props.item.fieldOrganizer}
                          </div>
                        )}
                        {this.props.item.fieldOrt && (
                          <div className="location">
                            <img src={`${self}/location.svg`} alt="GPS-Icon" />
                            <div>{this.props.item.fieldOrt}</div>
                          </div>
                        )}
                        {/* @todo fhe: Add ATC Function and show it to nil */}
                        {this.props.item && (
                          <a
                            href={getEncodedIcalUrl(
                              this.props.item.fieldDatum,
                              this.props.item.fieldOrt,
                              this.props.item.title,
                              this.props.item.fieldText
                            )}
                          >
                            <FormattedMessage id="event.atc" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-16 text teaser-text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.item.fieldText,
                    }}
                  />
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </article>
      </AccordionItem>
    );
  }
}

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldBild: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDatum: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicketlink: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserEvent)));
