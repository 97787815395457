import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import Contacts from "../../contacts/contacts";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserPerson extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-person">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.id}
          destinationRoute={this.props.location.pathname}
        />
        <div className="flex-wrap">
          <div className="img-wrap">
            <Image
              data={
                this.props.item.fieldBildWCaptionRawField.first.entity
                  .fieldMediaImage
              }
              nodeTitle={this.props.item.title}
              credit={
                this.props.item.fieldBildWCaptionRawField.first.entity
                  .fieldCredit
              }
            />
          </div>
          <div className="text-wrap">
            {this.props.item.fieldRolleOderFunktion && (
              <div className={"occupation-wrapper"}>
                {this.props.item.fieldRolleOderFunktion.map((item, index) => (
                  <span key={index} className="occupation">
                    {item.name}
                  </span>
                ))}
              </div>
            )}
            {this.props.item.title && <h3>{this.props.item.title}</h3>}
            {this.props.item.fieldBeschreibung && (
              <div
                className="description-text"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldBeschreibung,
                }}
              />
            )}
            <Contacts
              tel={this.props.item.fieldMobil}
              mail={this.props.item.fieldMail}
            />
          </div>
        </div>
      </article>
    );
  }
}

export const teaserPersonPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  fieldMail: PropTypes.string,
  fieldTelefon: PropTypes.string,
  fieldMobil: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldBeschreibung: PropTypes.string,
  fieldKontakinformationen: PropTypes.arrayOf(
    PropTypes.shape({
      fieldContactType: PropTypes.string.isRequired,
      fieldWert: PropTypes.string.isRequired,
    })
  ),
  fieldRolleOderFunktion: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldBildWCaptionRawField: PropTypes.shape({
    first: PropTypes.shape({
      customCaption: PropTypes.string,
      defaultCaption: PropTypes.bool,
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
});

TeaserPerson.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPerson));
