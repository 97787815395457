import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";
import ParagraphFacts from "./paragraph-facts";
import { self } from "../../../../config";

const ParagraphAkkordionText = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-akkordion-text": true,
  });
  const AbschnittSwitch = ({ item }) => {
    switch (item.__typename) {
      case "ParagraphFakten":
        return <ParagraphFacts content={item}/>;
      case "ParagraphTextAkkordionAbsatz":
        return (
          <div className="akkordion-text">
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: item.fieldText
              }}
            />
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-12 offset-lg-2">
            <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
              {content.fieldAbschnitte.map((item, index) => (
                <ErrorBoundary key={index}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="heading-flex-wrap">
                          <div>
                            {item.fieldTitel}
                          </div>
                          <img src={`${self}/arrow.svg`} alt="Pfeil zum öffnen/schließen" />
                        </div>

                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <AbschnittSwitch item={item}/>
                  </AccordionItemPanel>
                </AccordionItem>
                </ErrorBoundary>
                ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphAkkordionText.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        fieldTitel: PropTypes.string,
        fieldText: PropTypes.string,
      })
    ),
  }),
};

export default ParagraphAkkordionText;
