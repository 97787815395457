import React, { Component } from "react";
import PropTypes from "prop-types";
import {self} from "../config";

const LoadingIndicator = (props) => {
  return (
    <div
      className="loading-indicator-wrap"
      data-fullpage={props.fullPage}
    >
      <img
        src={`${self}/lfts-logo.svg`}
        className="logo"
        alt={"LFTS Logo"}
      />
    </div>
  );
}

LoadingIndicator.propTypes = {
  width: PropTypes.number,
};

export default LoadingIndicator;
