import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A module representing a text paragraph.
 */
const ParagraphText = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-text": true,
    "background-color": content.fieldFarbigHinterlegen,
  });
  const colClassNames = classNames({
    "split-cols" : content.fieldTwoCols === true,
    "col-wrapper col-16 col-md-12 offset-md-2": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className={colClassNames}>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: content.fieldText,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldFarbigHinterlegen: PropTypes.bool,
    fieldText: PropTypes.string.isRequired,
    fieldTwoCols: PropTypes.bool,
  }).isRequired,
};

export default ParagraphText;
