import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Accordion} from "react-accessible-accordion";
import TeaserMember from "../../../teaser-base/member/teaser-member";
import groupBy from 'lodash.groupby';
import LoadingIndicator from "../../../loading-indicator";
import ScrollSpy from "react-scrollspy-navigation";

const MemberOverview = (props) => {

  const [personsGrouped, setPersonsGrouped] = useState(null);
  const [anchorLinks, setAnchorLinks] = useState(null);

  const alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ];

  // Set Links of Scrollspy to wrapper in grouped content
  const initializeLinks = () => {
    let links = document.querySelectorAll('.character-wrapper'),
      anchorLinks = [];

    if ([...links].length > 0) {
      [...links].map(link => {
        anchorLinks.push(link.id);
      });
    }
    setAnchorLinks(anchorLinks);
  }

  // Group Persons by First Letter
  const groupPersons = (content) => {
    console.log(content);
    let personsGrouped = groupBy(content, item => item.title[0].toLowerCase().replace('ä', 'a').replace('ö', 'o').replace('ü', 'u'));
    let sorted = Object.keys(personsGrouped)
      .sort()
      .reduce((acc, key) => ({
        ...acc, [key]: personsGrouped[key]
      }), {});

    console.log(sorted);

    setPersonsGrouped(sorted);

  }

  useEffect(() => {
    groupPersons(props.content);
    initializeLinks();
  },[props.content.length]);


  if (alphabet && anchorLinks && personsGrouped) {
    return (
      <>
        <div className="sticky-alphabet-wrapper col-16" data-nosnippet>
          {alphabet && anchorLinks &&
            <div className="alphabet-menu">
              <div className="overflow-wrapper">
                <div className="scrollactive-nav">
                  <ScrollSpy className="active" offsetTop={-100}>
                    {alphabet && alphabet.map((character, characterIndex) =>
                      <a
                        href={`#section-${character}`}
                        className={`alphabet-character ${typeof personsGrouped[character] !== 'undefined' ? 'has-content' : 'no-content'}`}
                        key={characterIndex}
                        onClick={(e) => this.findSection(e, character)}
                        ref={React.createRef()}
                      >
                        {character}
                      </a>
                    )}
                  </ScrollSpy>
                </div>
              </div>
            </div>
          }
        </div>
        {personsGrouped ? (
          <div className="persons-wrapper col-16">
            {Object.keys(personsGrouped).map((sectionCharacter, index) =>
              <React.Fragment key={index}>
                <div className="character-wrapper" id={`section-${sectionCharacter}`}>
                  {personsGrouped[sectionCharacter].map((memberTeaser, memberIndex) =>
                    <React.Fragment key={memberIndex}>
                      <TeaserMember item={memberTeaser}/>
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        ) : (
          <LoadingIndicator fullPage={true} />
        )}
      </>
    );
  }
}

export const memberOverviewPropTypes = PropTypes.shape({
  id: PropTypes.object
});

MemberOverview.propTypes = {
  content: PropTypes.array.isRequired
};

export default MemberOverview;