import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { connect } from "react-redux";
import menuQuery from "../general-components/navigation/menu-query.graphql";
import { Menu } from "../general-components/navigation/menu";
import Search from "./../general-components/search/search";
import { menuOpenAction } from "../app-actions";
import Image from "../general-components/image/image";
import LogoButton from "../general-components/logo-button";
import ShortcutMenu from "./shortcut-menu";
import classNames from "classnames";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  menuOpen: reduxStore.appStore.menuOpen,
});

/**
 * Close on menu link click.
 */
class SimpleNavigation extends Component {
  state = {
    menuOpen: false,
    openTree: false,
  };
  toggleFullScreenMenu = () => {
    this.props.dispatch(menuOpenAction(!this.props.menuOpen));
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      this.toggleFullScreenMenu();
    }
  };

  render() {
    return (
      <>
        {this.props.showSearch && <Search />}

        <div
          id="toggle-fullscreen-menu"
          tabIndex={0}
          aria-label="toggle-full-screen-menu"
          onClick={() => this.toggleFullScreenMenu()}
          onKeyDown={this.handleKeyDown}
          className={this.props.menuOpen ? "active" : ""}
        >
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
          <div className="bar4" />
        </div>

        <div
          id="fullscreen-menu"
          className={this.props.menuOpen ? "active" : ""}
        >
          <div className="nav-wrap">
            <div className="container">
              <div className="row">
                <div className="col-16 main-menu-wrapper">
                  <Menu
                    menuName="MAIN"
                    navClassName="full-screen-menu"
                    itemOnClickHandler={this.toggleFullScreenMenu}
                  />
                </div>
                <div className="col-16 shortcut-menu-wrapper">
                  <ShortcutMenu />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const SingleLinkPropType = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
});

const LinkPropType = PropTypes.shape({
  link: SingleLinkPropType,
  subtree: PropTypes.arrayOf(
    PropTypes.shape({
      link: SingleLinkPropType,
      subtree: PropTypes.arrayOf(
        PropTypes.shape({
          link: SingleLinkPropType,
          /*TODO: Recursive? */
          subtree: PropTypes.array,
        })
      ).isRequired,
    })
  ).isRequired,
});

SimpleNavigation.propTypes = {
  showSearch: PropTypes.bool,
  mainMenuQuery: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    menuByName: PropTypes.shape({
      label: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(LinkPropType).isRequired,
    }),
  }),
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: () => ({
        variables: {
          name: "MAIN",
          /*language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",*/
        },
      }),
      name: "mainMenuQuery",
    })
  )(SimpleNavigation)
);
