import React, { Component } from "react";
import { self } from "../../config";

class Contacts extends Component {
  render() {
    return (
      <div className="contact-wrapper">
        <div className="contacts">
          {this.props.web &&
            <a
              href={this.props.web.uri.path}
              className="web info-span"
              target={"_blank"}
            >
              <img src={`${self}/emoji-icon.svg`} alt="website"/>
              <div className="value">{this.props.web.uri.path.replace("https://", "")}</div>
            </a>
          }
          {this.props.tel &&
            <a
              href={`tel:${this.props.tel}`}
              className="mobil info-span"
            >
              <img src={`${self}/tel-icon.svg`} alt="telefon icon"/>
              <div className="value">{this.props.tel}</div>
            </a>
          }
          {this.props.mail &&
            <a
              href={`mailto:${this.props.mail}`}
              className="mail info-span"
            >
              <img src={`${self}/mail-icon.svg`} alt="Email Icon" />
              <div className="value">{this.props.mail}</div>
            </a>
          }
          {this.props.address &&
            <span
              className="location info-span"
            >
              <img src={`${self}/location.svg`} alt="GPS Icon" />
              <div className="value">{this.props.address }</div>
            </span>
          }
          {this.props.insta &&
            <a
              href={this.props.insta.uri.path}
              className="insta info-span"
            >
              <img src={`${self}/instagram.svg`} alt="Instagram Icon"/>
              <div className="value">{this.props.insta.uri.path.replace("https://", "")}</div>
            </a>
          }
          {this.props.instaIcon &&
            <a
              href={this.props.instaIcon.uri.path}
              className="insta info-span"
            >
              <img src={`${self}/instagram.svg`} alt="Instagram Icon"/>
            </a>
          }
          {this.props.fb &&
            <a
              href={this.props.fb.uri.path}
              className="facebook info-span"
            >
              <img src={`${self}/share_fb.svg`} alt="Facebook Icon" />
              <div className="value">{this.props.fb.uri.path.replace("https://", "")}</div>
            </a>
          }
          {this.props.fbIcon &&
            <a
              href={this.props.fbIcon.uri.path}
              className="facebook info-span"
            >
              <img src={`${self}/share_fb.svg`} alt="Facebook Icon" />
            </a>
          }

        </div>
      </div>
    );
  }
}

export default Contacts;
