import React, { useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import axios from "axios";
import classNames from "classnames";

import { restHostBackend } from "../../../../../config";
import { states } from "../states";

const uploadFiles = (file, token, setFieldValue, identifier) => {
  let uploadfile = {
    name: file.name,
    file: file.file,
  };

  uploadfile = JSON.stringify(uploadfile);

  let fileID = "";

  axios({
    method: "post",
    url: `${restHostBackend}/api/webforms/createfile`,
    data: uploadfile,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": token,
    },
  })
    .then((res) => {
      fileID = res.data;
      console.log(res);
      setFieldValue(identifier, fileID);
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    });
};

const convertToBase64 = (file, token, setFieldValue, identifier) => {
  const reader = new FileReader(),
    tempFile = file;

  reader.readAsDataURL(file);
  reader.onload = () => {
    tempFile.file = reader.result;
    uploadFiles(tempFile, token, setFieldValue, identifier);
  };
  reader.onerror = (error) => {
    console.error(error);
  };
};

const FileField = ({ item, token, language, compositeIndex, compositeParent }) => {
  const { values, setFieldValue } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item["#states"],
    values
  );
  
  const identifier = compositeParent
    ? typeof compositeIndex !== "undefined"
      ? `${compositeParent}[${compositeIndex}][${item.id}]`
      : `${compositeParent}[${item.id}]`
    : item.id;

  // @todo Reset after submit.
  const [files, setFiles] = useState(null);

  const extensions = item["#type"] === "webform_image_file" ? [
    "gif",
    "jpg",
    "jpeg",
    "png",
  ] : [
    "gif",
    "jpg",
    "jpeg",
    "png",
    "bmp",
    "eps",
    "tif",
    "pict",
    "psd",
    "txt",
    "rtf",
    "html",
    "odf",
    "pdf",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "xml",
    "avi",
    "mov",
    "mp3",
    "mp4",
    "ogg",
    "wav",
    "bz2",
    "dmg",
    "gz",
    "jar",
    "rar",
    "sit",
    "svg",
    "tar",
    "zip",
  ];

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
        "file-field": true,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label
        htmlFor={identifier}
        role="button"
        title="Datei hinzufügen"
        aria-label="Datei hinzufügen"
        className={files !== null ? "d-none" : ""}
      >
        +
      </label>
      <input
        id={identifier}
        name={item.id}
        type="file"
        accept="application/pdf, image/*, .csv, text/plain, application/msword, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        className="form-control"
        required={(!!item["#required"] || required) && !optional && visible}
        disabled={!enabled || disabled}
        onChange={(event) => {
          const allowedExt = item["#file_extensions"]
            ? item["#file_extensions"]
            : extensions;

          if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const ext = file.name.match(/\.([^.]+)$/)[1];

            if (!allowedExt.includes(ext.toLowerCase())) {
              window.alert(`${file.name} has the wrong Fileformat!`);
              setFiles(null);
            }
            // If filesize is bigger than 5mb
            else if (file.size > 5000000) {
              window.alert(`${file.name} is to big! max Filesize 5MB!`);
              setFiles(null);
            } else {
              convertToBase64(file, token, setFieldValue, identifier);
              setFiles(file);
            }
          }
        }}
      />
      {!!item["#description"] && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item["#description"] }}
        />
      )}
      {files && (
        <div className="img-wrapper">
          <img src={files.file} />
        </div>
      )}
      {files != null && (
        <div className="file-info">
          <span>{files.name}</span>
          <button
            onClick={() => {
              setFiles(null);
              setFieldValue(item.id, "");
            }}
            className="btn"
          >
            <div className="overflow-wrapper">
              <div className="animation">
                <span data-title="x">x</span>
              </div>
            </div>
          </button>
        </div>
      )}
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
      />
    </div>
  );
};

FileField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    "#description": PropTypes.string,
    "#title": PropTypes.string,
    "#flex": PropTypes.number,
    "#required": PropTypes.bool,
    "#states": PropTypes.array,
    "#file_extensions": PropTypes.string,
    "#multiple": PropTypes.object,
  }),
  token: PropTypes.string,
  language: PropTypes.oneOf(["de", "en"]),
};

export default FileField;
