import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Link, { LinkPropType } from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";

const ParagraphCallToAction = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-call-to-action": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 d-flex justify-content-center">
            <ErrorBoundary>
              <Link
                className="btn btn-primary"
                link={content.fieldCallToAction}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphCallToAction.propTypes = {
  content: PropTypes.shape({
    fieldCallToAction: LinkPropType,
  }),
};

export default ParagraphCallToAction;
