import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

const ParagraphNewsletterRegistration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setErrorMsg(null);
    setIsLoading(true);

    const formData = new FormData(e.target);

    const formattedFormData = {
      email: formData.get("email"),
      consent: formData.get("consent") ? true : false,
    };

    if (formattedFormData.email === "") {
      setErrorMsg("newsletter.missing_email");
      setIsLoading(false);
      return;
    }

    if (!formattedFormData.consent) {
      setErrorMsg("newsletter.missing_consent");
      setIsLoading(false);
      return;
    }

    /**
     * Request is handled by the node server to avoid exposing the API-key.
     * The node server will send the request to Brevo.
     * Logic is handled by ./api/middleware-newsletter-registration.js
     */
    fetch("/api/newsletter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedFormData),
    })
      .then((response) => {
        if (response.ok) {
          setSuccessMsg("newsletter.success");
        } else {
          response.json().then((data) => setErrorMsg(data.error.code));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="paragraph paragraph-newsletter-registration">
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-12 offset-md-2 col-lg-8 offset-lg-4 d-flex justify-content-center">
            <h2 className="headline-xl">Newsletter</h2>
          </div>
        </div>
        {successMsg ? (
          <>
            <p className="hero text">
              <FormattedMessage id="thank_you" />
            </p>
            <p className="text">
              <FormattedMessage id={successMsg} />
            </p>
          </>
        ) : (
          <form onSubmit={formSubmitHandler}>
            <div className="row">
              <div className="col-16 col-md-12 offset-md-2 col-lg-8 offset-lg-4">
                <fieldset>
                  <label htmlFor="email" className="label">E-Mail</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    required
                  />
                </fieldset>
              </div>
            </div>
            <div className="row">
              <div className="col-16 col-md-12 offset-md-2 col-lg-8 offset-lg-4">
                <fieldset className="form-check">
                  <input type="checkbox" name="consent" id="consent" required />
                  <label htmlFor="consent">
                    Ich möchte Ihren Newsletter erhalten und akzeptiere die
                    Datenschutzerklärung. Sie können den Newsletter jederzeit
                    über den Link in unserem Newsletter abbestellen.
                  </label>
                </fieldset>
              </div>
            </div>
            {errorMsg && (
              <div className="row">
                <div className="col-16 col-md-12 offset-md-2 col-lg-8 offset-lg-4">
                  <FormattedMessage id={errorMsg} />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-16 col-md-12 offset-md-2 col-lg-8 offset-lg-4 d-flex justify-content-center">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-primary"
                >
                  {isLoading ? "sendet..." : "senden"}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

ParagraphNewsletterRegistration.propTypes = {};

export default ParagraphNewsletterRegistration;
