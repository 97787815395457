import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";

import Image from "../../../image/image";

const ParagraphTeaserImage = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-image": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div
          className={classNames({
            row: true,
            "flex-row-reverse":
              content.fieldImagePosition === "right",
          })}
        >
          <div
            className={classNames({
              "col-16 col-lg-8": true,
              "offset-lg-1": content.fieldImagePosition === "right",
            })}
          >
            <Image
              data={content.fieldImage.fieldMediaImage}
              nodeTitle={content.fieldHeading}
              credit={content.fieldImage.fieldCredit}
            />
          </div>
          <div
            className={classNames({
              "col-16 col-lg-7": true,
              "offset-lg-1": content.fieldImagePosition === "left",
            })}
          >
            <h3>{content.fieldHeading}</h3>
            {content.fieldText && (
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: content.fieldText,
                }}
              />
            )}
            {content.fieldCallToAction && (
              <Link
                className="btn btn-primary"
                link={content.fieldCallToAction}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.string,
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
