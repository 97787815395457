import React from "react";
import { useSelector } from "react-redux";
import NodeWrapper from "../components/node-wrapper";
import useNodeQuery from "../../hooks/use-node-query";
import newsPageQuery from "./news.graphql";
import PagerFullPage from "../../general-components/pager-full-page";
import ContentBase from "../../general-components/content-base/content-base";

const News = () => {
  /**
   * Hook to get information from Redux Store
   */
  const pagerConfig = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPages
  );
  const pagerFullPageContextId = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPageContextId
  );

  const { entity, loading, error } = useNodeQuery(newsPageQuery);

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      {entity?.fieldModules && (
        <ContentBase content={entity.fieldModules.list} />
      )}

      {pagerFullPageContextId &&
        pagerConfig.filter(
          (config) => config.id === pagerFullPageContextId
        ).length > 0 && (
          <PagerFullPage
            config={
              pagerConfig.filter(
                (config) => config.id === pagerFullPageContextId
              )[0]
            }
            currentId={entity.entityId}
          />
        )}
    </NodeWrapper>
  );
};

News.propTypes = {};

export default News;
