import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image/image";

const ParagraphBilderSlider = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-bilder-slider": true,
    [`format-${content.fieldFormat}`]: true,
  });

  return (
    <section className={sectionClassNames}>
      {content.fieldFormat !== "left" && content.fieldFormat !== "right" ? (
        <div>Bitte rechts oder Links als orentierung wählen</div>
      ) : (
        <div className="container">
          <div className="row">
            <div
              className={classNames({
                "col-16": true,
                "col-lg-6": true,
                "offset-lg-2": content.fieldFormat === "right",
              })}
            >
              <Image
                data={content.fieldBilder[0].fieldMediaImage}
                credit={content.fieldBilder[0].fieldCredit}
              />
            </div>
            <div
              className={classNames({
                "col-16": true,
                "col-lg-6": true,
                "offset-lg-2": true,
              })}
            >
              <Image
                data={content.fieldBilder[1].fieldMediaImage}
                credit={content.fieldBilder[1].fieldCredit}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaptionRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.bool,
          entity: PropTypes.shape({
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string.isRequired,
              }),
            }),
          }),
        })
      ),
    }),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default ParagraphBilderSlider;
