import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import { List32, Grid32, ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";
import {FormattedMessage} from "react-intl";
import groupBy from 'lodash.groupby';
import moment from "moment";
import Slider from "react-slick";

import teaserNodeQueryFilterTag
  from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../loading-indicator";
import TeaserBase from "../../../teaser-base/teaser-base";
import ComponentFilterCloud
  from "../extended-teaser-list/components/component-filter-cloud";
import {
  timelineSetActiveFilterAction,
  timelineRemoveActiveFilterAction,
} from "./actions-timeline";
import {useQuery} from "@apollo/client";
import { BrowserView, isMobile, isTablet, MobileView, isBrowser } from "react-device-detect";
import CustomArrow from "../../../../controls/custom-arrow";

/*
 * @todo: Pager.
 */
const sliderSettings = {
  arrows: true,
  dots: false,
  nextArrow: <CustomArrow classNames={classNames}/>,
  prevArrow: <CustomArrow classNames={classNames}/>,
  slidesToShow: 2,
  slidesToScroll: 1,
  touchMove: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};
const ParagraphTimeline = ({content}) => {

  const [activeFilter, setActiveFilter] = useState({});
  const [possibleYears, setPossibleYears] = useState([]);
  const [activeYearFilter, setActiveYearFilter] = useState(moment().format("YYYY"));

  const reduxStore = useSelector((reduxStore) => ({
    timelineConfig: reduxStore.timeLine.config,
    currentLanguage: reduxStore.i18n.currentLanguage,
  }));
  const [showArchive,setShowArchiv] = useState(content.fieldShowArchive)
  const dispatch = useDispatch();

  const {data: nodes} = useQuery(
    teaserNodeQueryFilterTag,
    (() => {
      const tagConfig = reduxStore.timelineConfig.filter(
        (item) => item.timeLineId === content.id
      );
      const tagFiltered = tagConfig.length === 1 && tagConfig[0].config.id;

      let tags = [];

      // TODO: Not tag multi select, just one tag at a time.
      if (tagFiltered) {
        tags = [tagConfig[0].config.id];
      }

      if (content.fieldSchlagwort.length > 0) {
        for (const tag of content.fieldSchlagwort) {
          tags = [...tags, parseInt(tag.id, 10)];
        }
      }

      return {
        variables: {
          limit: showArchive  ? 20 : 3,
          type: content.fieldTypenTimeline,
          offset: 0,
          status: ["1"],
          tag: tags,
          filterTagEnabled: tags.length > 0,
          language: reduxStore.currentLanguage.toUpperCase(),
        },
      };
    })()
  );

  const loadMoreNodes = () =>
    nodes.fetchMore({
      variables: {
        offset: nodes.entityQuery.items.length,
      },
      updateQuery(previousResult, {fetchMoreResult}) {
        if (!fetchMoreResult) {
          return previousResult;
        }

        fetchMoreResult.entityQuery.items = [
          ...previousResult.entityQuery.items,
          ...fetchMoreResult.entityQuery.items,
        ];

        return fetchMoreResult;
      },
    });

  const changeActiveFilter = (filter) => {
    if (filter.id === activeFilter.id) {
      setActiveFilter({});
      dispatch(timelineRemoveActiveFilterAction(content.id));
    }
    else {
      setActiveFilter(filter);
      dispatch(
        timelineSetActiveFilterAction({
          timeLineId: content.id,
          config: filter,
        })
      );
    }
  };

  const getPossibleYears = (items) => {
    let years = groupBy(items, item => moment(item.publishedAt).format("YYYY"));
    let possibleYears = Object.keys(years).sort().reverse();

    setPossibleYears(possibleYears);
  }

  const changeActiveYear = (year) => {
    setActiveYearFilter(year);
  }

  const filterForYear = (nodes) => {
    return nodes.filter((node) => moment(node.publishedAt).format("YYYY") === activeYearFilter);
  }

  const sectionClassNames = classNames({
    "paragraph paragraph-timeline": true,
  });

  if (!nodes) {
    return;
  }
  if (nodes.entityQuery?.items && possibleYears.length === 0 && showArchive) {
    getPossibleYears(nodes.entityQuery.items);
  }


  let items = filterForYear(nodes.entityQuery?.items) || [];
  let loadMore =
    filterForYear(nodes.entityQuery?.items).length > 0 &&
    filterForYear(nodes.entityQuery?.items).length < nodes.entityQuery?.total;

  if (!items.length) {
    return <LoadingIndicator/>;
  }
  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "col-16": true,
              "col-lg-14 offset-lg-1 col-xxl-12 offset-xxl-2": !showArchive,
              "archive" : showArchive,
              })}
            >
              {content.fieldTitel && (
                <h2 className="title-overview headline-xl">
                  {content.fieldTitel}
                </h2>
              )}
              {content.fieldShowArchive && possibleYears && possibleYears.length > 1 && (
                <div className="filter-wrapper years">
                  {possibleYears
                    .map((year, index) => (
                      <button className={`filter year ${year === activeYearFilter ? 'active' : 'inactive'}`} key={index} onClick={() => changeActiveYear(year)}>
                        {year}
                      </button>
                    ))}
                </div>
              )}
              <div className="row big-wrapper">
                {(isBrowser || showArchive) &&
                  <>
                    {items.map((item, index) => (
                      <div className="col" key={index}>
                        <div className="teaser-wrapper d-flex" key={index}>
                          <TeaserBase
                            item={item}
                            key={index}
                            viewMode={"timeline"}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                }


                {isMobile && !showArchive &&
                  <div className="mobile-slider">
                    <Slider {...sliderSettings}>
                      {items.map((item, index) => (
                        <div className="col-16">
                          <div className="teaser-wrapper d-flex" key={index}>
                            <TeaserBase
                              item={item}
                              key={index}
                              viewMode={"timeline"}
                            />
                          </div>
                        </div>
                      ))}
                    </Slider>

                  </div>
                }
              </div>
            </div>
          </div>
            {content.fieldZeigeMehrLaden && !showArchive && isBrowser && (
              <div className="row">
                <div
                  className="col-16 btn-wrapper d-flex justify-content-center">
                  <div className="btn btn-primary" onClick={() => setShowArchiv(true)}>
                    <FormattedMessage id="load_more"/>
                  </div>
                </div>
              </div>
            )}
        </div>
      </section>
    );


};

ParagraphTimeline.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fieldFilterwolke: PropTypes.bool,
    fieldListenumschalter: PropTypes.bool,
    fieldZeigeMehrLaden: PropTypes.bool,
    fieldShowArchive: PropTypes.bool,
    fieldTypenTimeline: PropTypes.arrayOf(
      PropTypes.oneOf(["news", "blogbeitrag"])
    ),
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    fieldTitel: PropTypes.string,
  }),
};

export default ParagraphTimeline;
