import React from "react";
import PropTypes from "prop-types";
import VideoEmbedded from "./video-embedded";
import VideoFile from "./video-file";
import ErrorBoundary from "../../../../../error-boundary";

const Video = ({ content, onPlay }) => {
  return (
    <>
      {(() => {
        switch (content.entityBundle) {
          case "embedded_video":
            return (
              <ErrorBoundary>
                <VideoEmbedded content={content} onPlay={onPlay}/>
              </ErrorBoundary>
            );
          case "video":
            return (
              <ErrorBoundary>
                <VideoFile content={content} onPlay={onPlay} />
              </ErrorBoundary>
            );
          default:
            return false;
        }
      })()}
    </>
  );
};

Video.propTypes = {
  content: PropTypes.object.isRequired,
  onPlay: PropTypes.func,
};

export default Video;
