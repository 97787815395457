import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import menuQuery from "./menu-query.graphql";

export const Menu = ({
  menuName,
  showLabel = false,
  itemOnClickHandler,
  itemClassName = "",
  itemIcon,
  listClassName = "",
  navClassName = "",
}) => {
  const { loading, error, data } = useQuery(menuQuery, {
    variables: { name: menuName },
  });
  if (loading) {
    return "Loading...";
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  if (!data.menuByName?.links?.length) {
    return;
  }

  return (
    <div className={menuName}>
      {showLabel && <h2>{data.menuByName.label}</h2>}
      <nav className={`${data.menuByName.id} ${navClassName}`}>
        <ul className={listClassName}>
          {data.menuByName.links.map((item, index) => (
            <li
              onClick={() => (itemOnClickHandler ? itemOnClickHandler() : null)}
              className={itemClassName}
              key={index}
            >
              <Link to={item.link.url.path} title={item.link.description}>
                {itemIcon && itemIcon}
                {item.link.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

Menu.propTypes = {
  menuName: PropTypes.string,
  showLabel: PropTypes.bool,
  itemOnClickHandler: PropTypes.func,
  itemClassName: PropTypes.string,
  itemIcon: PropTypes.element,
  listClassName: PropTypes.string,
  navClassName: PropTypes.string,
};
