import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { useScrollDirection } from '../lib/use-scroll-direction';

import SimpleNavigation from "./simple-navigation";
import LogoButton from "../general-components/logo-button";
import ShortcutMenu from "./shortcut-menu";
import { Menu } from "../general-components/navigation/menu";
import {useDispatch, useSelector} from "react-redux";
import {
  menuOpenAction
} from "../app-actions";

const Header = (props) => {
  const scrollDirection = useScrollDirection();
  const menuOpen = useSelector((reduxStore) => reduxStore.appStore.menuOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (scrollDirection !== "up") {
      dispatch(menuOpenAction(false));
    }

  },[scrollDirection]);

  {/* @Todo: trigger maximal view type by clicking on menu button in simple-navigation.jsx */}
  return (
    <header id="site-header" active-header-style={scrollDirection === "up" || menuOpen ? 'maximal' : 'minimal'}>
      <div className="fixed-wrapper">
        <div className="transition-wrapper">
          <div className={"main-header"}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-4 d-flex justify-content-start">
                  <LogoButton type={"maximal"}/>
                </div>
                <div className="col-12">
                  <ShortcutMenu/>
                  <Menu menuName="MAIN" navClassName="main-menu-desktop"  />
                </div>
              </div>
            </div>
          </div>
          <div className="minimal-header">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-4 col-md-2 d-flex justify-content-start">
                  <LogoButton type={"minimal"}/>
                </div>
                <SimpleNavigation showSearch={props.showSearch} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  showSearch: PropTypes.bool,
};

export default Header;
