import React, { useEffect } from "react";
import memoize from "memoize-one";

// Router
import { Route, Switch, useLocation } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import PageFooter from "./footer/page-footer";
import Header from "./header/header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";
import WebformPage from "./intern/webform-page/webform-page";
import ResetPasswordWithTempPasswordForm from "./intern/reset-password-with-temp-password-form";
import ForgotPasswordForm from "./intern/forgot-password-form";
import LoginForm from "./intern/login-form";

// Nodes
import Landingpage from "./nodes/landingpage/landingpage";
import Event from "./nodes/event/event";
import Person from "./nodes/person/person";
import News from "./nodes/news/news";
import Error404 from "./system/error-404";
import NewsletterDOIConfirmed from "./general-components/content-base/paragraphs/newsletter-registration/confirmation/newsletter-doi-confirmed";

export const defaultLocale = "de",
  defaultMessages = messages_de;

const messages = {
  und: defaultMessages,
  de: messages_de,
  en: messages_en,
};

const App = () => {
  const location = useLocation();
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);

  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  const generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  useEffect(() => {
    document.body.className += `${generateMainClasses(location.pathname)}`;
  }, []);

  useEffect(() => {
    // Scroll to top on route change
    if (!location.hash) {
      window.scrollTo(0, 0);
      document.body.className = `${generateMainClasses(location.pathname)}`;
    }
  }, [location.pathname]);

  return (
    <IntlProvider
      locale={currentLanguage !== "und" ? currentLanguage : defaultLocale}
      defaultLocale={defaultLocale}
      messages={messages[currentLanguage]}
    >
      <div className="main-content">
        <ErrorBoundary>
          <Header showSearch={false} />
        </ErrorBoundary>

        <main className="main-page-content">
          <ErrorBoundary>
            <Switch location={location} key={location.pathname}>
              {/* Internal Pages */}
              <Route exact path="/intern/login" component={LoginForm} />
              <Route
                exact
                path="/intern/forgot-password"
                component={ForgotPasswordForm}
              />
              <Route
                exact
                path="/intern/reset-pass/temp"
                component={ResetPasswordWithTempPasswordForm}
              />
              <Route
                exact
                path="/intern/webform/:webformId/:submissionId?"
                component={WebformPage}
              />
              <Route exact path="/intern/:alias" component={Landingpage} />
              {/* Public Pages */}
              <Route
                exact
                path="/:language?/projekt/:alias"
                component={Landingpage}
              />
              <Route
                exact
                path="/:language?/news/:alias"
                component={News}
              />
              <Route
                exact
                path="/:language?/veranstaltung/:alias"
                component={Event}
              />
              <Route
                exact
                path="/:language?/person/:alias"
                component={Person}
              />
              <Route 
                exact
                path="/newsletter-doi-confirmed"
                component={NewsletterDOIConfirmed}
              />
              <Route 
                exact
                path="/:language?/:alias+"
                component={Landingpage}
              />
              <Route
                exact
                path="/node/:nodeId/revisions/:revisionId/view"
                component={NodeRevision}
              />
              <Route exact path="/:language?" component={Landingpage} />
              <Route exact path="/" component={Landingpage} />
              <Route path="*" component={Error404} status={404} />
            </Switch>
          </ErrorBoundary>
        </main>

        <ErrorBoundary>
          <PageFooter />
        </ErrorBoundary>
      </div>
    </IntlProvider>
  );
};

App.propTypes = {};

export default App;
