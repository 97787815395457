import React from "react";

function CustomArrow({onClick, className}) {
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 28 47"
        version="1.1"
        viewBox="0 0 28 47"
        xmlSpace="preserve"
        className="slick-arrow chiffron"
      >
        <path
          d="M22.6 44.4L1.4 23.3 22.6 2.2c1-1 2.5-1 3.5 0s1 2.5 0 3.5L9.3 22.4c-.5.5-.5 1.3 0 1.8L26 41c1 1 1 2.5 0 3.5-.9.9-2.5.9-3.4-.1z"
          className="st0"
        ></path>
      </svg>
    </div>

  );
}

export default CustomArrow;